import Vue from "vue";
Vue.mixin({
  methods: {
    sortArray(array, sortedOrder, key) {
      array.sort(function(a, b) {
        var A = a[key],
          B = b[key];

        let indA = sortedOrder.indexOf(A);
        let indB = sortedOrder.indexOf(B);

        if (indA == -1) indA = sortedOrder.length - 1;
        if (indB == -1) indB = sortedOrder.length - 1;

        if (indA < indB) {
          return -1;
        } else if (indA > indB) {
          return 1;
        }
        return 0;
      });

      return array;
    },
  },
});
