<template>
  <div>
    <div class="m-5">
      <div class="w-100 mt-5 d-flex" style="justify-content: end;">
        <div class="font-medium ProximaNovaRegular d-flex">
          <span :style="isDisable ? 'color: red' : 'color: green'">{{
            message
          }}</span>
        </div>
      </div>
      <w-table
        :tableProperties="tableDataValuesMedia"
        :showHeading="true"
        :headingText="'Data Sufficiency Check '"
        :customRowColor="true"
        :tableLoading="tableLoading"
      />
    </div>
    <div
      class="m-5 d-flex"
      style="align-items: center;justify-content: space-between;"
    >
      <div class="d-flex">
        <w-button
          :buttonLoader="'none'"
          class="mr-3 addtionalDetail"
          :buttonText="'Additional Details'"
          :isDisable="tableLoading"
          :class="[
            {
              resetPasswordLink: tableLoading,
            },
            showAdditionalDetailsButton ? '' : 'd-none',
          ]"
          @buttonClicked="showAdditionalDetails = !showAdditionalDetails"
        >
        </w-button>
      </div>
      <div
        class="d-flex"
        style="align-items: center;justify-content: space-between;"
      >
        <!--<h3 class="ProximaNovaBold">Recommendation</h3>-->
        <div>
          <w-button
            :buttonLoader="buttonLoader"
            :buttonText="'Create Plan'"
            :isDisable="isDisableCheck"
            :class="{
              resetPasswordLink: isDisableCheck,
            }"
            @buttonClicked="continueToNext"
          >
          </w-button>
        </div>
      </div>

      <!--<div class="font-medium ProximaNovaRegular d-flex">
        <span :style="isDisable ? 'color: red' : 'color: green'">{{
          message
        }}</span>
      </div>-->
    </div>
    <div class="m-5" v-if="showAdditionalDetails">
      <w-table
        :tableProperties="tableDataValues"
        :showHeading="true"
        :tableLoading="tableLoading"
        :headingText="'Data Continuity Check'"
        :customRowColor="true"
      />
    </div>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import { AIDigitalPoem } from "../../services/AIDigitalPoemServices";
import Button from "@/components/Profile/Button.vue";
const aiDigitalPoem = new AIDigitalPoem();
export default {
  components: {
    "w-table": DataTable,
    "w-button": Button,
  },
  props: {
    kpiSource: {
      type: Array,
      default: () => [],
    },
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    kpiCountry: {
      type: String,
      default: "",
    },
    buttonLoader: {
      type: String,
      default: "normal",
    },
  },
  data() {
    return {
      message: "",
      tableLoading: false,

      isDisable: true,
      showAdditionalDetails: false,
      tableDataValues: {
        fields: [
          {
            key: "NAME",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "COUNT",
            label: "Number of Records",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "chnageBoolean",
            label: "Data Continuous",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          //   {
          //     key: "chnageBoolean1",
          //     label: "Data Sufficient",
          //     thClass: "thClassLeft",
          //     tdClass: "text-left",
          //     sortable: false,
          //   },
          {
            key: "Granularity",
            label: "Granularity",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      tableDataValuesMedia: {
        fields: [
          {
            key: "NAME",
            label: "Platform",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "COUNT",
            label: "Number of Records",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "chnageBoolean1",
            label: "Data Sufficient",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "Granularity",
            label: "Granularity",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [
          {
            COUNT: "",
            "DATA SUFFICIENT": "loader",
            Granularity: "",
            NAME: "Trends",
          },
          {
            COUNT: "",
            "DATA SUFFICIENT": "loader",
            Granularity: "",
            NAME: "Analytics",
          },
          {
            COUNT: "",
            "DATA SUFFICIENT": "loader",
            Granularity: "",
            NAME: "Digital Media",
          },
        ],
      },
      apiCallDone: "notDone",
      showAdditionalDetailsButton: false,
    };
  },
  methods: {
    getTableData() {
      aiDigitalPoem
        .getDataSufficent(
          // "ywxxfnxcnqgamtvohspo",
          sessionStorage.getItem("subId"),
          this.kpiCountry,
          { KpiSource: this.kpiSource, startDate: this.startDate }
        )
        .then((res) => {
          if (res.messageCode === 400) {
            this.message = res.message;
            this.$emit("updateError");
            this.apiCallDone = "failed";
            this.changeToFalse();
            return;
          } else {
            this.message = res.Recommendation;
          }

          this.tableDataValues.items = [];
          this.tableDataValues.items = res.values;
          this.tableDataValuesMedia.items = [];
          this.tableDataValuesMedia.items = res.SufficiencyCheck;
          this.isDisable = !res["Pass State"];
          this.apiCallDone = "Done";
        })
        .catch(() => {
          this.$emit("updateError");
          this.apiCallDone = "failed";
          this.changeToFalse();
        });
    },
    changeToFalse() {
      for (var i = 0; i < this.tableDataValuesMedia.items.length; i++) {
        if (this.apiCallDone === "failed") {
          this.tableDataValuesMedia.items[i]["DATA SUFFICIENT"] = false;
        } else if (this.apiCallDone === "notDone") {
          this.tableDataValuesMedia.items[i]["DATA SUFFICIENT"] = "loader";
        }
      }
    },
    continueToNext() {
      this.$emit("startModal");
    },
  },
  computed: {
    isDisableCheck() {
      if (
        !this.isDisable &&
        this.buttonLoader === "normal" &&
        this.apiCallDone === "Done"
      ) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getTableData();
    if (
      sessionStorage.getItem("parentEmail").split("@")[1] === "datapoem.com"
    ) {
      this.showAdditionalDetailsButton = true;
    } else {
      this.showAdditionalDetailsButton = false;
    }
  },
};
</script>

<style scoped>
.addtionalDetail {
  height: 38px;
  float: right;
  padding: 0px;
  font-size: small;
}
</style>
