<template>
  <div>
    <Toast
      v-if="toastData.show"
      :type="toastData.type"
      :message="toastData.message"
      :show="toastData.show"
      :time="10000"
      @clearToast="clearToast"
    />
    <div class="w-100">
      <div class="row m-0 p-0" style="justify-content: end;">
        <w-button
          style="width: auto;"
          :buttonLoader="'none'"
          :buttonText="'Create New Connection'"
          @buttonClicked="allConnectorModal"
        >
        </w-button>
      </div>
    </div>
    <DataStreamModal
      ref="dataStreamModal"
      @createStreamDetails="createStreamDetails($event)"
    />
    <DataFetchModal ref="dataFetchModalRef" @fetchStatus="processDone" />
    <ConnectionModal
      @createConnection="createNewConnection($event)"
      ref="connectionModal"
    />
    <AuthorizedModal
      ref="authorizeModal"
      @authorizedDone="authoriseConnection"
    />
    <AllConnectionModal
      :selectedConnectors="selectedConnectors"
      :selectedConnectorsForViewModal="allConnectorList"
      :title="'All Connectors'"
      ref="overViewModal"
      @createNewConnction="createNewConnction"
    />
  </div>
</template>

<script>
import Button from "../components/Profile/Button.vue";
import DataStreamModal from "@/components/Modal/DataStreamModal.vue";
import DataFetchModal from "@/components/Modal/DataFetchModal.vue";
import ConnectionModal from "@/components/Modal/ConnectionModal.vue";
import AuthorizedModal from "@/components/Modal/AuthorizedModal.vue";
import AllConnectionModal from "@/components/Modal/AllConnectionModal.vue";
import { DataConnection } from "@/services/DataConnectionService.js";
import { ApiHelper } from "@/services/helper/ApiHelper.js";
import Toast from "@/components/Toast/Toast.vue";
import moment from "moment";
let apiHelper = new ApiHelper();
const dataConnection = new DataConnection();
export default {
  components: {
    "w-button": Button,
    DataStreamModal,
    DataFetchModal,
    ConnectionModal,
    AuthorizedModal,
    AllConnectionModal,
    Toast,
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      allConnectorList: [
        {
          image: "google",
          name: "Google Ads",
          connectionId: 250,
          role: "DM Google Ads",
        },
        {
          name: "Facebook Ads",
          image: "facebook",
          role: "DM Facebook",
          connectionId: 208,
        },
        {
          name: "LinkedIn Ads",
          image: "linkedin",
          role: "DM LinkedIn",
          connectionId: 315,
        },
        {
          name: "Twitter Ads",
          image: "twitter",
          role: "DM Twitter",
          connectionId: 462,
        },
        {
          name: "Display and Video 360",
          image: "dv360",
          role: "DM DV360",
          connectionId: 255,
        },
        {
          name: "Microsoft Ads",
          image: "microsoft",
          role: "DM Microsoft Ads",
          connectionId: 349,
        },
        {
          name: "Pinterest",
          image: "Pinterest",
          role: "DM Pinterest",
          connectionId: 380,
        },
        {
          name: "Snapchat",
          image: "Snapchat",
          role: "DM Snapchat",
          connectionId: 424,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "DM google sheets",
          connectionId: 267,
        },

        {
          name: "360.sn",
          image: "360.sn",
          role: "Search Advertising 360.sn",
          connectionId: null,
        },
        {
          name: "Amazon Advertising",
          image: "Amazon",
          role: "Search Advertising Amazon Advertising",
          connectionId: null,
        },
        {
          name: "Apple Search Ads",
          image: "Apple Search Ads",
          role: "Search Advertising Apple Search Ads",
          connectionId: null,
        },
        {
          name: "Googel Adwords",
          image: "google-ad",
          role: "Search Advertising Googel Adwords",
          connectionId: null,
        },
        {
          name: "Google Ads New",
          image: "google-ad",
          role: "Search Advertising Google Ads New",
          connectionId: null,
        },
        {
          name: "Google Keyword Planner",
          image: "google-ad",
          role: "Search Advertising Google Keyword Planner",
          connectionId: null,
        },
        {
          name: "Google Search ads 360",
          image: "Google Search ads 360",
          role: "Search Advertising Google Search ads 360",
          connectionId: null,
        },
        {
          name: "indeed sponsored jobs",
          image: "indeed sponsored jobs",
          role: "Search Advertising indeed sponsored jobs",
          connectionId: null,
        },
        {
          name: "Microsoft Ads",
          image: "MicrosoftAds",
          role: "Search Advertising Microsoft Ads",
          connectionId: null,
        },
        {
          name: "The Search Monitor",
          image: "The Search Monitor",
          role: "Search Advertising The Search Monitor",
          connectionId: null,
        },
        {
          name: "Yabdex",
          image: "Yabdex",
          role: "Search Advertising Yabdex",
          connectionId: null,
        },
        {
          name: "Yahoo Japan Ads",
          image: "Yahoo Japan Ads",
          role: "Search Advertising Yahoo Japan Ads",
          connectionId: null,
        },
        {
          name: "Yello Pages",
          image: "Yello Pages",
          role: "Search Advertising Yello Pages",
          connectionId: null,
        },
        {
          name: "Yelp Ads",
          image: "Yelp Ads",
          role: "Search Advertising Yelp Ads",
          connectionId: null,
        },

        {
          name: "Google Analytics",
          image: "google-anal",
          role: "DM google-anal",
          connectionId: 270,
        },

        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "WA google sheets",
          connectionId: 267,
        },
        {
          name: "Adobe Analytics (Service)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics (Service)",
          connectionId: null,
        },
        {
          name: "Adobe Analytics",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics",
          connectionId: null,
        },
        {
          name: "Adobe Analytics(WSSE)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics(WSSE)",
          connectionId: null,
        },
        {
          name: "Convert",
          image: "Convert",
          role: "WEB ANAlytics Convert",
          connectionId: null,
        },
        {
          name: "Etracker",
          image: "Etracker",
          role: "WEB ANAlytics Etracker",
          connectionId: null,
        },
        {
          name: "Google Analytics(Oauth)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Oauth)",
          connectionId: null,
        },
        {
          name: "Google Analytics(Service)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Service)",
          connectionId: null,
        },
        {
          name: "IBM Analytics",
          image: "IBM Analytics",
          role: "WEB ANAlytics IBM Analytics",
          connectionId: null,
        },
        {
          name: "Magnite Performance",
          image: "Magnite Performance",
          role: "WEB ANAlytics Magnite Performance",
          connectionId: null,
        },
        {
          name: "Mixpanel",
          image: "Mixpanel",
          role: "WEB ANAlytics Mixpanel",
          connectionId: null,
        },
        {
          name: "Optimizely",
          image: "Optimizely",
          role: "WEB ANAlytics Optimizely",
          connectionId: null,
        },
        {
          name: "Piwik Pro",
          image: "Piwik Pro",
          role: "WEB ANAlytics Piwik Pro",
          connectionId: null,
        },
        {
          name: "Similar Web",
          image: "Similar Web",
          role: "WEB ANAlytics Similar Web",
          connectionId: null,
        },
        {
          name: "Site Impact",
          image: "Site Impact",
          role: "WEB ANAlytics Site Impact",
          connectionId: null,
        },
        {
          name: "Webtrekk",
          image: "Webtrekk",
          role: "WEB ANAlytics Webtrekk",
          connectionId: null,
        },
        {
          name: "Webtrends",
          image: "Webtrends",
          role: "WEB ANAlytics Webtrends",
          connectionId: null,
        },

        {
          name: "Facebook Insights",
          image: "facebook",
          role: "DM Facebook Page Insights",
          connectionId: 690,
        },
        {
          name: "Instagram Insights",
          image: "instagram",
          role: "DM Instagram Insights",
          connectionId: 214,
        },
        {
          name: "YouTube Insights",
          image: "youtube",
          role: "DM Youtube Insights",
          connectionId: 284,
        },
        {
          name: "LinkedIn Insights",
          image: "linkedin",
          role: "DM linkedin insights",
          connectionId: 319,
        },
        {
          name: "Twitter Insights",
          image: "twitter",
          role: "DM Twitter Insights",
          connectionId: 462,
        },
        {
          name: "Google Ads Keyword Planner",
          image: "google",
          role: "DM Google Ads Keyword Planner",
          connectionId: 741,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "DM google sheets",
          connectionId: 267,
        },

        {
          name: "Call Gear",
          image: "Call Gear",
          role: "Call Analytics Call Gear",
          connectionId: null,
        },
        {
          name: "Call Rail",
          image: "Call Rail",
          role: "Call Analytics Call Rail",
          connectionId: null,
        },
        {
          name: "Marchex",
          image: "Marchex",
          role: "Call Analytics Marchex",
          connectionId: null,
        },
        {
          name: "Response Tab",
          image: "Response Tab",
          role: "Call Analytics Response Tab",
          connectionId: null,
        },

        {
          name: "Adalyser",
          image: "Adalyser",
          role: "Social Analytics Adalyser",
          connectionId: null,
        },
        {
          name: "Awario",
          image: "Awario",
          role: "Social Analytics Awario",
          connectionId: null,
        },
        {
          name: "Brandwatch",
          image: "brandWatch",
          role: "Social Analytics Brandwatch",
          connectionId: null,
        },
        {
          name: "Facebook Page Insights",
          image: "Facebook",
          role: "Social Analytics Facebook Page Insights",
          connectionId: null,
        },
        {
          name: "Google My Business",
          image: "Google My Business",
          role: "Social Analytics Google My Business",
          connectionId: null,
        },
        {
          name: "Instagram Basic Dislplay",
          image: "Instagram",
          role: "Social Analytics Instagram Basic Dislplay",
          connectionId: null,
        },
        {
          name: "Instagram Business",
          image: "Instagram Business",
          role: "Social Analytics Instagram Business",
          connectionId: null,
        },
        {
          name: "Keyhole",
          image: "keyHole",
          role: "Social Analytics Keyhole",
          connectionId: null,
        },
        {
          name: "Linkedin organisations and brands",
          image: "LinkedIn",
          role: "Social Analytics Linkedin organisations and brands",
          connectionId: null,
        },
        {
          name: "Linkfluence Radarly",
          image: "Linkfluence Radarly",
          role: "Social Analytics Linkfluence Radarly",
          connectionId: null,
        },
        {
          name: "netbase",
          image: "netbase",
          role: "Social Analytics netbase",
          connectionId: null,
        },
        {
          name: "Socialbakers",
          image: "Socialbakers",
          role: "Social Analytics Socialbakers",
          connectionId: null,
        },
        {
          name: "Sprinklr",
          image: "Sprinklr",
          role: "Social Analytics Sprinklr",
          connectionId: null,
        },
        {
          name: "Sprinklr Prod2",
          image: "Sprinklr",
          role: "Social Analytics Sprinklr Prod2",
          connectionId: null,
        },
        {
          name: "Synthesio",
          image: "Synthesio",
          role: "Social Analytics Synthesio",
          connectionId: null,
        },
        {
          name: "Talkwalker",
          image: "Talkwalker",
          role: "Social Analytics Talkwalker",
          connectionId: null,
        },
        {
          name: "Twitch",
          image: "Twitch",
          role: "Social Analytics Twitch",
          connectionId: null,
        },
        {
          name: "Unmetric",
          image: "Unmetric",
          role: "Social Analytics Unmetric",
          connectionId: null,
        },
        {
          name: "Youtube Analytics",
          image: "youtube",
          role: "Social Analytics Youtube Analytics",
          connectionId: null,
        },
        {
          name: "Youtube public communications",
          image: "youtube",
          role: "Social Analytics Youtube public communications",
          connectionId: null,
        },
        {
          name: "Youtube Public",
          image: "youtube",
          role: "Social Analytics Youtube Public",
          connectionId: null,
        },

        {
          name: "Adjust",
          image: "Adjust",
          role: "Mobile Adjust",
          connectionId: null,
        },
        {
          name: "APP Annie",
          image: "APP Annie",
          role: "Mobile APP Annie",
          connectionId: null,
        },
        {
          name: "App Follow",
          image: "App Follow",
          role: "Mobile App Follow",
          connectionId: null,
        },
        {
          name: "App Figures",
          image: "appfigures",
          role: "Mobile appfigures",
          connectionId: null,
        },
        {
          name: "AppLovin",
          image: "AppLovin",
          role: "Mobile AppLovin",
          connectionId: null,
        },
        {
          name: "Apps Flyer",
          image: "appsFlyer",
          role: "Mobile Apps Flyer",
          connectionId: null,
        },
        {
          name: "apptweak",
          image: "apptweak",
          role: "Mobile apptweak",
          connectionId: null,
        },
        {
          name: "Branch Querry API",
          image: "Branch Querry API",
          role: "Mobile Branch Querry API",
          connectionId: null,
        },
        {
          name: "Branch",
          image: "Branch Querry API",
          role: "Mobile Branch",
          connectionId: null,
        },
        {
          name: "Fyber",
          image: "Fyber",
          role: "Mobile Fyber",
          connectionId: null,
        },
        {
          name: "Iron Source",
          image: "Iron Source",
          role: "Mobile Iron Source",
          connectionId: null,
        },
        {
          name: "Kochava",
          image: "Kochava",
          role: "Mobile Kochava",
          connectionId: null,
        },
        {
          name: "NativeX",
          image: "NativeX",
          role: "Mobile NativeX",
          connectionId: null,
        },
        {
          name: "Priori data",
          image: "Priori data",
          role: "Mobile Priori data",
          connectionId: null,
        },
        {
          name: "SPlicky",
          image: "SPlicky",
          role: "Mobile SPlicky",
          connectionId: null,
        },
        {
          name: "tune-branch",
          image: "tune-branch",
          role: "Mobile tune-branch",
          connectionId: null,
        },
        {
          name: "Uberall",
          image: "Uberall",
          role: "Mobile Uberall",
          connectionId: null,
        },

        {
          name: "Active Campaign",
          image: "Active Campaign",
          role: "CRM Active Campaign",
          connectionId: null,
        },
        {
          name: "Braze",
          image: "Braze",
          role: "CRM Braze",
          connectionId: null,
        },
        {
          name: "Campaign Monitor",
          image: "Campaign Monitor",
          role: "CRM Campaign Monitor",
          connectionId: null,
        },
        {
          name: "Cordial",
          image: "Cordial",
          role: "CRM Cordial",
          connectionId: null,
        },
        {
          name: "Emarsys",
          image: "Emarsys",
          role: "CRM Emarsys",
          connectionId: null,
        },
        {
          name: "Evalanche",
          image: "Evalanche",
          role: "CRM Evalanche",
          connectionId: null,
        },
        {
          name: "Hubspot Destination",
          image: "Hubspot Destination",
          role: "CRM Hubspot Destination",
          connectionId: null,
        },
        {
          name: "Hubspot",
          image: "Hubspot Destination",
          role: "CRM Hubspot",
          connectionId: null,
        },
        {
          name: "Iterable",
          image: "Iterable",
          role: "CRM Iterable",
          connectionId: null,
        },
        {
          name: "Klaviyo",
          image: "Klaviyo",
          role: "CRM Klaviyo",
          connectionId: null,
        },
        {
          name: "Mailchimp",
          image: "Mailchimp",
          role: "CRM Mailchimp",
          connectionId: null,
        },
        {
          name: "Mailing Work",
          image: "Mailing Work",
          role: "CRM Mailing Work",
          connectionId: null,
        },
        {
          name: "Mailworx",
          image: "Mailworx",
          role: "CRM Mailworx",
          connectionId: null,
        },
        {
          name: "Marketo",
          image: "Marketo",
          role: "CRM Marketo",
          connectionId: null,
        },
        {
          name: "Microsoft Dynamics",
          image: "Microsoft Dynamics",
          role: "CRM Microsoft Dynamics",
          connectionId: null,
        },
        {
          name: "Piano",
          image: "Piano",
          role: "CRM Piano",
          connectionId: null,
        },
        {
          name: "Salesforce Marketing Cloud",
          image: "salesforce",
          role: "CRM Salesforce Marketing Cloud",
          connectionId: null,
        },
        {
          name: "Salesforce",
          image: "salesforce",
          role: "CRM Salesforce",
          connectionId: null,
        },
        {
          name: "Sendinblue",
          image: "Sendinblue",
          role: "CRM Sendinblue",
          connectionId: null,
        },
        {
          name: "Sharpspring",
          image: "Sharpspring",
          role: "CRM Sharpspring",
          connectionId: null,
        },
        {
          name: "Teamleader",
          image: "Teamleader",
          role: "CRM Teamleader",
          connectionId: null,
        },
        {
          name: "Zendesk Sell",
          image: "Zendesk Sell",
          role: "CRM Zendesk Sell",
          connectionId: null,
        },
        {
          name: "Google Trends",
          image: "Google Trends",
          role: "DM Google Trends",
          connectionId: 791,
        },

        {
          name: "Amazon Marketplace",
          image: "Amazon",
          role: "Commerce Amazon Marketplace",
          connectionId: null,
        },
        {
          name: "Amazon Selling Partner",
          image: "Amazon",
          role: "Commerce Amazon Selling Partner",
          connectionId: null,
        },
        {
          name: "Channel Pilot",
          image: "Channel Pilot",
          role: "Commerce Channel Pilot",
          connectionId: null,
        },
        {
          name: "Dataweave",
          image: "dataweave",
          role: "Commerce dataweave",
          connectionId: null,
        },
        {
          name: "Digistore24",
          image: "Digistore24",
          role: "Commerce Digistore24",
          connectionId: null,
        },
        {
          name: "Ebay",
          image: "Ebay",
          role: "Commerce Ebay",
          connectionId: null,
        },
        {
          name: "Feed Dynamix",
          image: "Feed Dynamix",
          role: "Commerce Feed Dynamix",
          connectionId: null,
        },
        {
          name: "Google Content for Shopping",
          image: "Google Content for Shopping",
          role: "Commerce Google Content for Shopping",
          connectionId: null,
        },
        {
          name: "Magneto",
          image: "Magneto",
          role: "Commerce Magneto",
          connectionId: null,
        },
        {
          name: "Shopalyst",
          image: "Shopalyst",
          role: "Commerce Shopalyst",
          connectionId: null,
        },
        {
          name: "Shopee",
          image: "Shopee",
          role: "Commerce Shopee",
          connectionId: null,
        },
        {
          name: "Shopify",
          image: "Shopify",
          role: "Commerce Shopify",
          connectionId: null,
        },
        {
          name: "Tweakers",
          image: "Tweakers",
          role: "Commerce Tweakers",
          connectionId: null,
        },
        {
          name: "Woo commerce",
          image: "Woo commerce",
          role: "Commerce Woo commerce",
          connectionId: null,
        },

        {
          name: "Azure Synapse",
          image: "Azure Synapse",
          role: "Data Base Azure Synapse",
          connectionId: null,
        },
        {
          name: "Microsoft SQL Server",
          image: "Microsoft SQL Server",
          role: "Data Base Microsoft SQL Server",
          connectionId: null,
        },
        {
          name: "Mongo DB",
          image: "Mongo DB",
          role: "Data Base Mongo DB",
          connectionId: null,
        },
        {
          name: "My SQL",
          image: "My SQL",
          role: "Data Base My SQL",
          connectionId: null,
        },
        {
          name: "Oracle",
          image: "Oracle",
          role: "Data Base Oracle",
          connectionId: null,
        },
        {
          name: "Postgre SQl",
          image: "Postgre SQl",
          role: "Data Base Postgre SQl",
          connectionId: null,
        },
        {
          name: "Snowflake",
          image: "Snowflake",
          role: "Data Base Snowflake",
          connectionId: null,
        },

        {
          name: "Amazon Athena",
          image: "Amazon Athena",
          role: "Data Lake Amazon Athena",
          connectionId: null,
        },
        {
          name: "Box",
          image: "Box",
          role: "Data Lake Box",
          connectionId: null,
        },
        {
          name: "Databricks",
          image: "Databricks",
          role: "Data Lake Databricks",
          connectionId: null,
        },
        {
          name: "Dropbox",
          image: "Dropbox",
          role: "Data Lake Dropbox",
          connectionId: null,
        },
        {
          name: "Egnyte EU",
          image: "Egnyte EU",
          role: "Data Lake Egnyte EU",
          connectionId: null,
        },
        {
          name: "Egnyte US",
          image: "Egnyte EU",
          role: "Data Lake Egnyte US",
          connectionId: null,
        },
        {
          name: "File Azure SAS",
          image: "File Azure SAS",
          role: "Data Lake File Azure SAS",
          connectionId: null,
        },
        {
          name: "File Azure",
          image: "File Azure",
          role: "Data Lake File Azure",
          connectionId: null,
        },
        {
          name: "File one Drive",
          image: "File one Drive",
          role: "Data Lake File one Drive",
          connectionId: null,
        },
        {
          name: "File",
          image: "File",
          role: "Data Lake File",
          connectionId: null,
        },
        {
          name: "Google Drive",
          image: "google-drive",
          role: "Data Lake Google Drive",
          connectionId: null,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "Data Lake Google Sheets",
          connectionId: null,
        },
        {
          name: "Hadoop",
          image: "Hadoop",
          role: "Data Lake Hadoop",
          connectionId: null,
        },
        {
          name: "S3 Connection",
          image: "S3 Connection",
          role: "Data Lake S3 Connection",
          connectionId: null,
        },
        {
          name: "SFTP Public Key",
          image: "File",
          role: "Data Lake SFTP Public Key",
          connectionId: null,
        },
        {
          name: "Sharepoint Online",
          image: "Sharepoint Online",
          role: "Data Lake Sharepoint Online",
          connectionId: null,
        },
        {
          name: "WebDAV",
          image: "File",
          role: "Data Lake WebDAV",
          connectionId: null,
        },

        {
          name: "Adform DMP OAuth2",
          image: "Adform DMP OAuth2",
          role: "DMP Adform DMP OAuth2",
          connectionId: null,
        },
        {
          name: "Adobe Experience PLatform",
          image: "Adobe Experience PLatform",
          role: "DMP Adobe Experience PLatform",
          connectionId: null,
        },
        {
          name: "Audience Studio",
          image: "Audience Studio",
          role: "DMP Audience Studio",
          connectionId: null,
        },

        {
          name: "Google Big Query (Oauth)",
          image: "genericGoogle",
          role: "Generic Google Big Query (Oauth)",
          connectionId: null,
        },
        {
          name: "Google BigQuery (Service)",
          image: "genericGoogle",
          role: "Generic Google BigQuery (Service)",
          connectionId: null,
        },
        {
          name: "Google Cloud Storage",
          image: "Google Cloud Storage",
          role: "Generic Google Cloud Storage",
          connectionId: null,
        },
        {
          name: "Microsoft SQL Server",
          image: "Microsoft SQL Server",
          role: "Generic Microsoft SQL Server",
          connectionId: null,
        },

        {
          name: "Google Analytics",
          image: "google-anal",
          role: "DM google-anal",
          connectionId: 270,
        },

        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "WA google sheets",
          connectionId: 267,
        },
        {
          name: "Adobe Analytics (Service)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics (Service)",
          connectionId: null,
        },
        {
          name: "Adobe Analytics",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics",
          connectionId: null,
        },
        {
          name: "Adobe Analytics(WSSE)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics(WSSE)",
          connectionId: null,
        },
        {
          name: "Convert",
          image: "Convert",
          role: "WEB ANAlytics Convert",
          connectionId: null,
        },
        {
          name: "Etracker",
          image: "Etracker",
          role: "WEB ANAlytics Etracker",
          connectionId: null,
        },
        {
          name: "Google Analytics(Oauth)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Oauth)",
          connectionId: null,
        },
        {
          name: "Google Analytics(Service)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Service)",
          connectionId: null,
        },
        {
          name: "IBM Analytics",
          image: "IBM Analytics",
          role: "WEB ANAlytics IBM Analytics",
          connectionId: null,
        },
        {
          name: "Magnite Performance",
          image: "Magnite Performance",
          role: "WEB ANAlytics Magnite Performance",
          connectionId: null,
        },
        {
          name: "Mixpanel",
          image: "Mixpanel",
          role: "WEB ANAlytics Mixpanel",
          connectionId: null,
        },
        {
          name: "Optimizely",
          image: "Optimizely",
          role: "WEB ANAlytics Optimizely",
          connectionId: null,
        },
        {
          name: "Piwik Pro",
          image: "Piwik Pro",
          role: "WEB ANAlytics Piwik Pro",
          connectionId: null,
        },
        {
          name: "Similar Web",
          image: "Similar Web",
          role: "WEB ANAlytics Similar Web",
          connectionId: null,
        },
        {
          name: "Site Impact",
          image: "Site Impact",
          role: "WEB ANAlytics Site Impact",
          connectionId: null,
        },
        {
          name: "Webtrekk",
          image: "Webtrekk",
          role: "WEB ANAlytics Webtrekk",
          connectionId: null,
        },
        {
          name: "Webtrends",
          image: "Webtrends",
          role: "WEB ANAlytics Webtrends",
          connectionId: null,
        },

        {
          name: "Active Campaign",
          image: "Active Campaign",
          role: "CRM Active Campaign",
          connectionId: null,
        },
        {
          name: "Braze",
          image: "Braze",
          role: "CRM Braze",
          connectionId: null,
        },
        {
          name: "Campaign Monitor",
          image: "Campaign Monitor",
          role: "CRM Campaign Monitor",
          connectionId: null,
        },
        {
          name: "Cordial",
          image: "Cordial",
          role: "CRM Cordial",
          connectionId: null,
        },
        {
          name: "Emarsys",
          image: "Emarsys",
          role: "CRM Emarsys",
          connectionId: null,
        },
        {
          name: "Evalanche",
          image: "Evalanche",
          role: "CRM Evalanche",
          connectionId: null,
        },
        {
          name: "Hubspot Destination",
          image: "Hubspot Destination",
          role: "CRM Hubspot Destination",
          connectionId: null,
        },
        {
          name: "Hubspot",
          image: "Hubspot Destination",
          role: "CRM Hubspot",
          connectionId: null,
        },
        {
          name: "Iterable",
          image: "Iterable",
          role: "CRM Iterable",
          connectionId: null,
        },
        {
          name: "Klaviyo",
          image: "Klaviyo",
          role: "CRM Klaviyo",
          connectionId: null,
        },
        {
          name: "Mailchimp",
          image: "Mailchimp",
          role: "CRM Mailchimp",
          connectionId: null,
        },
        {
          name: "Mailing Work",
          image: "Mailing Work",
          role: "CRM Mailing Work",
          connectionId: null,
        },
        {
          name: "Mailworx",
          image: "Mailworx",
          role: "CRM Mailworx",
          connectionId: null,
        },
        {
          name: "Marketo",
          image: "Marketo",
          role: "CRM Marketo",
          connectionId: null,
        },
        {
          name: "Microsoft Dynamics",
          image: "Microsoft Dynamics",
          role: "CRM Microsoft Dynamics",
          connectionId: null,
        },
        {
          name: "Piano",
          image: "Piano",
          role: "CRM Piano",
          connectionId: null,
        },
        {
          name: "Salesforce Marketing Cloud",
          image: "salesforce",
          role: "CRM Salesforce Marketing Cloud",
          connectionId: null,
        },
        {
          name: "Salesforce",
          image: "salesforce",
          role: "CRM Salesforce",
          connectionId: null,
        },
        {
          name: "Sendinblue",
          image: "Sendinblue",
          role: "CRM Sendinblue",
          connectionId: null,
        },
        {
          name: "Sharpspring",
          image: "Sharpspring",
          role: "CRM Sharpspring",
          connectionId: null,
        },
        {
          name: "Teamleader",
          image: "Teamleader",
          role: "CRM Teamleader",
          connectionId: null,
        },
        {
          name: "Zendesk Sell",
          image: "Zendesk Sell",
          role: "CRM Zendesk Sell",
          connectionId: null,
        },

        {
          name: "Moat",
          image: "Moat",
          role: "Brand Safety Moat",
          connectionId: null,
        },

        {
          name: "Clockify",
          image: "Clockify",
          role: "Time Traker Clockify",
          connectionId: null,
        },
        {
          name: "Harvest",
          image: "Harvest",
          role: "Time Traker Harvest",
          connectionId: null,
        },
        {
          name: "Yanomo",
          image: "Yanomo",
          role: "Time Traker Yanomo",
          connectionId: null,
        },

        {
          name: "Audience Report",
          image: "Audience Report",
          role: "Audience Research Audience Report",
          connectionId: null,
        },
        {
          name: "Cxense",
          image: "Cxense",
          role: "Audience Research Cxense",
          connectionId: null,
        },
        {
          name: "Nielsen Ad intel",
          image: "nielsen",
          role: "Audience Research Nielsen Ad intel",
          connectionId: null,
        },
        {
          name: "Nielsen DAR",
          image: "nielsen",
          role: "Audience Research Nielsen DAR",
          connectionId: null,
        },
        {
          name: "Reppublika",
          image: "Reppublika",
          role: "Audience Research Reppublika",
          connectionId: null,
        },
        {
          name: "YouGov Brandindex",
          image: "YouGov Brandindex",
          role: "Audience Research YouGov Brandindex",
          connectionId: null,
        },

        {
          name: "Open Weather Map",
          image: "Open Weather Map",
          role: "Weather Open Weather Map",
          connectionId: null,
        },
        {
          name: "Weather Stack",
          image: "Weather Stack",
          role: "Weather Weather Stack",
          connectionId: null,
        },

        {
          name: "Adbook+",
          image: "Adbook+",
          role: "Others Adbook+",
          connectionId: null,
        },
        {
          name: "Bright Talk",
          image: "Bright Talk",
          role: "Others Bright Talk",
          connectionId: null,
        },
        {
          name: "Bynder",
          image: "Bynder",
          role: "Others Bynder",
          connectionId: null,
        },
        {
          name: "Drift",
          image: "Drift",
          role: "Others Drift",
          connectionId: null,
        },
        {
          name: "Google Calender",
          image: "Google Calender",
          role: "Others Google Calender",
          connectionId: null,
        },
        {
          name: "GoTo Webinar",
          image: "GoTo Webinar",
          role: "Others GoTo Webinar",
          connectionId: null,
        },
        {
          name: "Innovid",
          image: "Innovid",
          role: "Others Innovid",
          connectionId: null,
        },
        {
          name: "Invoca",
          image: "Invoca",
          role: "Others Invoca",
          connectionId: null,
        },
        {
          name: "Kenshoo",
          image: "Kenshoo",
          role: "Others Kenshoo",
          connectionId: null,
        },
        {
          name: "Mailgun",
          image: "Mailgun",
          role: "Others Mailgun",
          connectionId: null,
        },
        {
          name: "Media Wizard",
          image: "Media Wizard",
          role: "Others Media Wizard",
          connectionId: null,
        },
        {
          name: "Norstat",
          image: "Norstat",
          role: "Others Norstat",
          connectionId: null,
        },
        {
          name: "Podigee",
          image: "Podigee",
          role: "Others Podigee",
          connectionId: null,
        },
        {
          name: "Spotwatch",
          image: "Spotwatch",
          role: "Others Spotwatch",
          connectionId: null,
        },
        {
          name: "Strpe",
          image: "Strpe",
          role: "Others Strpe",
          connectionId: null,
        },
        {
          name: "TXGB",
          image: "TXGB",
          role: "Others TXGB",
          connectionId: null,
        },
        {
          name: "Unbounce",
          image: "Unbounce",
          role: "Others Unbounce",
          connectionId: null,
        },
        {
          name: "Usabilla",
          image: "Usabilla",
          role: "Others Usabilla",
          connectionId: null,
        },
        {
          name: "Web Connect (Bearer)",
          image: "Web Connect",
          role: "Others Web Connect (Bearer)",
          connectionId: null,
        },
        {
          name: "Web Connect (Oauth)",
          image: "Web Connect",
          role: "Others Web Connect (Oauth)",
          connectionId: null,
        },
        {
          name: "Web Connect (Oauth2)",
          image: "Web Connect",
          role: "Others Web Connect (Oauth2)",
          connectionId: null,
        },
        {
          name: "Web Connect",
          image: "Web Connect",
          role: "Others Web Connect",
          connectionId: null,
        },
        {
          name: "Zendesk",
          image: "Zendesk",
          role: "Others Zendesk",
          connectionId: null,
        },
      ],
      selectedConnectors: [],
      allBrandsList: [],
      allConnectionList: [],
      authorize: { url: "", id: "" },
      currentConnection: {},
    };
  },
  methods: {
    allConnectorModal() {
      this.$refs.overViewModal.open();
    },
    createNewConnction(event) {
      this.allBrandsList = [];
      dataConnection
        .getAllBrands(
          sessionStorage.getItem("userId"),
          sessionStorage.getItem("subId")
        )
        .then((res) => {
          console.log(event);
          this.getConnectionName(event);
          for (var i = 0; i < res.response.length; i++) {
            this.allBrandsList.push({
              text: res.response[i].brandName,
              id: res.response[i].id,
            });
          }
        });
    },
    getConnectionName(obj) {
      dataConnection.getAllConnectionList().then((res) => {
        for (var i = 0; i < res.response.length; i++) {
          this.allConnectionList.push(res.response[i].name);
          if (i === res.response.length - 1) {
            this.$refs.overViewModal.hideOverviewPage();
            this.$refs.connectionModal.open(
              obj,
              this.allBrandsList,
              this.allConnectionList
            );
          }
        }
      });
    },
    authoriseConnection() {
      var url = "";
      if (
        this.currentConnection.channelName.toLowerCase() ===
          "Google Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google Analytics".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Analytics".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google Sheets".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Sheets".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Microsoft Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Facebook Ads".toLowerCase() ||
          this.currentConnection.channelName.toLowerCase() ===
          "Display and Video 360".toLowerCase() ||
          this.currentConnection.channelName.toLowerCase() ===
          "DV 360".toLowerCase()
      ) {
        url = JSON.parse(this.authorize.url);
      } else {
        url = this.authorize.url;
      }
      var winobj = window.open(
        url,
        "Snopzer",
        "left=500,top=150,width=500,height=500,toolbar=1,resizable=0"
      );
      var self = this;
      var loop = setInterval(
        function() {
          if (winobj.closed) {
            const uri = `health-monitor-module/connection_isauthorized?connectionId=${
              self.authorize.id
            }&connectionType=${
              self.currentConnection.connectionId
            }&orgNumber=${sessionStorage.getItem("subId")}&channelName=${
              self.currentConnection.channelName
            }`;
            apiHelper.get(uri).then((res) => {
              if (res.response && res.status === "SUCCESS") {
                self.$refs.authorizeModal.hideAuthorizedPage();
                self.createDataStream({
                  ConnectionName: self.currentConnection.name,
                  connectionId: self.authorize.id,
                  channel: self.currentConnection.channelName,
                  showMessage: true,
                });
              } else {
                self.$refs.authorizeModal.hideAuthorizedPage();
              }
            });
            clearInterval(loop);
          }
        }.bind(this),
        100
      );
    },
    createNewConnection(data) {
      this.currentConnection = data;
      dataConnection.createConnection(data).then((res) => {
        this.$refs.connectionModal.hideWelcomePage();
        if (data.channelName === "Google Trends") {
          this.createDataStream({
            ConnectionName: this.currentConnection.name,
            connectionId: res.response[1],
            channel: this.currentConnection.channelName,
          });
          return;
        }
        this.authorize.url = res.response[0];
        this.authorize.id = res.response[1];
        this.$refs.authorizeModal.open();
      });
    },
    createDataStream(e) {
      this.$refs.dataStreamModal.open({
        title: "Add data stream",
        details: e,
      });
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    createStreamDetails(data) {
      dataConnection.createDataStream(data.details, data.typeId).then((res) => {
        this.$refs.dataStreamModal.hideWelcomePage();
        if (res.status === "FAILED") {
          this.toastData = {
            show: true,
            type: "failed",
            message: "Something went wrong! Please try after some time.",
          };
          return;
        }
        var id = data.details.createdBy.split("@")[1];
        if (data.details.channelName === "Google Trends") {
          this.fetchGoogleTrendsData(res, "");
          return;
        } else if (
          data.details.channelName !== "Google Trends" &&
          id !== "datapoem.com" &&
          !Object.keys(data.details).includes("file_id")
        ) {
          this.fetchThreeYearData(res);
          return;
        }
        if (Object.keys(data.details).includes("file_id")) {
          this.fetchGoogleTrendsData(res, data.details.file_id);
          return;
        } else {
          this.$refs.dataFetchModalRef.open({
            title: "Initial Data Fetch",
            id: res,
          });
        }
      });
    },
    fetchGoogleTrendsData(detail, id) {
      var data;
      if (id !== "") {
        data = {
          dataStreamId: detail,
          duration: 0,
          clientId: sessionStorage.getItem("clientId"),
          // fetchType: this.selectedPattern,
          historicStartDate: "",
          historicEndDate: "",
          start: moment(new Date()).format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
          file_id: id,
        };
      } else {
        data = {
          dataStreamId: detail,
          duration: 0,
          clientId: sessionStorage.getItem("clientId"),
          // fetchType: this.selectedPattern,
          historicStartDate: "",
          historicEndDate: "",
          start: moment(new Date()).format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
        };
      }
      dataConnection.fetchData(data).then((res) => {
        console.log(res);
        this.processDone();
      });
    },
    processDone() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, your datastream has been created successfully",
      };
      this.allConnectorModal();
    },
    fetchThreeYearData(detail) {
      var data = {
        dataStreamId: detail,
        duration: 0,
        clientId: sessionStorage.getItem("clientId"),
        // fetchType: this.selectedPattern,
        historicStartDate: moment(
          new Date(new Date().setFullYear(new Date().getFullYear() - 3))
        ).format("YYYY-MM-DD"),
        historicEndDate: moment(new Date()).format("YYYY-MM-DD"),
        start: "",
        end: "",
      };
      dataConnection.fetchData(data).then((res) => {
        console.log(res);
        this.processDone();
      });
    },
  },
  created() {
    this.allConnectorList = this.allConnectorList.filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );
  },
};
</script>

<style></style>
