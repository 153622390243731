var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"m-5"},[_c('div',{staticClass:"w-100 mt-5 d-flex",staticStyle:{"justify-content":"end"}},[_c('div',{staticClass:"font-medium ProximaNovaRegular d-flex"},[_c('span',{style:(_vm.isDisable ? 'color: red' : 'color: green')},[_vm._v(_vm._s(_vm.message))])])]),_c('w-table',{attrs:{"tableProperties":_vm.tableDataValuesMedia,"showHeading":true,"headingText":'Data Sufficiency Check ',"customRowColor":true,"tableLoading":_vm.tableLoading}})],1),_c('div',{staticClass:"m-5 d-flex",staticStyle:{"align-items":"center","justify-content":"space-between"}},[_c('div',{staticClass:"d-flex"},[_c('w-button',{staticClass:"mr-3 addtionalDetail",class:[
          {
            resetPasswordLink: _vm.tableLoading,
          },
          _vm.showAdditionalDetailsButton ? '' : 'd-none',
        ],attrs:{"buttonLoader":'none',"buttonText":'Additional Details',"isDisable":_vm.tableLoading},on:{"buttonClicked":function($event){_vm.showAdditionalDetails = !_vm.showAdditionalDetails}}})],1),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","justify-content":"space-between"}},[_c('div',[_c('w-button',{class:{
            resetPasswordLink: _vm.isDisableCheck,
          },attrs:{"buttonLoader":_vm.buttonLoader,"buttonText":'Create Plan',"isDisable":_vm.isDisableCheck},on:{"buttonClicked":_vm.continueToNext}})],1)])]),(_vm.showAdditionalDetails)?_c('div',{staticClass:"m-5"},[_c('w-table',{attrs:{"tableProperties":_vm.tableDataValues,"showHeading":true,"tableLoading":_vm.tableLoading,"headingText":'Data Continuity Check',"customRowColor":true}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }