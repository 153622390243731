import { render, staticRenderFns } from "./TwitterInsightsDetails.vue?vue&type=template&id=f85d8bc2&scoped=true&"
import script from "./TwitterInsightsDetails.vue?vue&type=script&lang=js&"
export * from "./TwitterInsightsDetails.vue?vue&type=script&lang=js&"
import style0 from "./TwitterInsightsDetails.vue?vue&type=style&index=0&id=f85d8bc2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f85d8bc2",
  null
  
)

export default component.exports